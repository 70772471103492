//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'Header',
  data () {
    return {
      items: [
        {
          title: 'Text (520) 376-2020',
          style: 'u-action',
          loc: '+15203762020'
        }
      ]
    }
  },
  methods: {
    navigate (location) {
      window.location.href = `sms:${location}`
      this.$ma.trackEvent({action: '[Text] Header Nav'})
    }
  }
}
