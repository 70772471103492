//
//
//
//
//
//
//
//
//

import HeaderFill from '../Shared/Header'

export default {
  name: 'About',
  components: {
    HeaderFill
  },
  data () {
  }
}
