//

import { Line } from 'vue-chartjs'

export default {
  extends: Line,
  props: ['data'],
  data () {
    return {
      gradient: ''
    }
  },
  mounted () {
    let labels = [];
    let dataset = [];
    for (let key in this.data) {
      let val = this.data[key];
      labels.push(new Date(val.created))
      dataset.push(val.value)
      console.log(val)
    }

    let data = {
      labels,
      datasets: [
        {
          label: 'Your Mood',
          backgroundColor: 'rgba(108,97,215,0.1)',
          borderColor: 'rgba(108,97,215,1)',
          borderWidth: '2',
          borderJoinStyle: 'miter',
          clip: 1,
          lineTension: '0',
          data: dataset,
          fontFamily: "'Trust'"
        }
      ]
    }

    let options = {
      scales: {
        yAxes: [{
          ticks: {
            min: 1,
            max: 5,
            stepSize: 1
          },
        }],
        xAxes: [{
          type: 'time',
          distribution: 'series',
          time: {
            unit: 'day',
            unitStepSize: 1,
            displayFormats: {
              'day': 'MMM DD'
            }
          },
          ticks: {
            autoSkip: true,
            maxTicksLimit: 5,
            fontFamily: "'Trust'",
            fontColor: 'rgba(180,180,180,1)'
          }
        }]
      },
      legend: {
        labels: {
          fontFamily: "'Trust'",
        }
      },
      responsive: true,
      maintainAspectRatio: false,
    }

    this.renderChart(data, options)

  }
}
