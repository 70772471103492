//
//
//
//
//
//
//
//
//


var API_URL = 'https://covid19000.herokuapp.com';

import UserChart from './UserChart';

export default {
  name: 'User',
  components: {
    UserChart
  },
  data () {
    return {
      ourData: null,
      error: false,
    }
  },
  computed: {
    userId () {
      return this.$route.params.id
    },
  },
  created () {
    this.$ma.trackEvent({action: 'User View'})
    this.$http
      .get(`${API_URL}/api/tally/${this.userId}`).then(res => {
        this.ourData = res.body.data;
      }).catch(() => {
        this.$ma.trackEvent({action: '[Error] User View'})
        this.error = true;
      });
  },
  methods: {
    generateEmail () {
      return 'mailto:thomas@subtract.design'
    }
  }
}
