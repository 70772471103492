import Vue from 'vue'
import App from './App.vue'
import VueResource from 'vue-resource'
import VueRouter from 'vue-router'
import store from './store'
import Headful from 'vue-headful';
import VueMultianalytics from 'vue-multianalytics'

import Home from './components/Home'
import About from './components/Route/About'
import User from './components/Route/User/User'

Vue.use(VueResource);
Vue.use(VueRouter)
Vue.component('vue-headful', Headful);
Vue.config.productionTip = false

const router = new VueRouter({
  mode: 'history',
  routes: [
    {
      path: '/',
      component: Home,
      name: 'home',
      meta: {
        analytics: 'Home'
      }
    },
    {
      path: '/user/:id',
      name: 'user',
      component: User,
    }
  ]
})

Vue.filter('SMS', function (value) {
  if (!value) return ''
  let string = value.split('+1')
  string = string[1].slice()
  return `(${string[0]}${string[1]}${string[2]}) ${string[3]}${string[4]}${string[5]}-${string[6]}${string[7]}${string[8]}${string[9]}`
})

let mixpanelConfig = {
  token: 'd7aef267896ac569ce39a710a5dd5049'
}

Vue.use(VueMultianalytics, {
  modules: {
    mixpanel: mixpanelConfig
  },
  routing: {
    vueRouter: router, //  Pass the router instance to automatically sync with router (optional)
    preferredProperty: 'page' // By default 'path' and related with vueRouter (optional)
  }
})

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
