//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import HeaderFill from './Shared/Header'

export default {
  name: 'Home',
  components: {
    HeaderFill
  },
  data () {
    return {
      number: '+15203762020',
      messages: [
        {
          text: 'Hey, it\'s Moody. How are you feeling today? (1-5)',
          style: 'is-from'
        },
        {
          text: '3',
          style: 'is-to'
        },
        {
          text: 'Thank you for responding. 89 other people reported the same. If you want to see your mood on a chart, head here.',
          style: 'is-from'
        },
      ]
    }
  },
  mounted () {
    this.$ma.trackEvent({action: 'Homepage View'})
  },
  methods: {
    navigate (number) {
      window.location.href = `sms:${number}?body=Hey Moody, let's get started.`
      this.$ma.trackEvent({action: '[Text] Main Homepage'})
    },
    generateEmail () {
      return 'mailto:thomas@subtract.design'
    }
  }
}
